import { Component, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { environment } from 'src/app/shared/environments/environment'

@Component({
    selector: 'app-cross-logout',
    templateUrl: './cross-logout.component.html',
    styleUrls: ['./cross-logout.component.scss']
})
export class CrossLogoutComponent implements OnInit {
    crossLogoutUrl: SafeResourceUrl

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.crossLogoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${environment.shareRootUrl}/logout`
        )
    }
}
