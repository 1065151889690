import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable({
    providedIn: 'root'
})
export class StripeGuard implements CanActivate {
    constructor(private authSrv: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route.url[0].path == 'subscription') {
            localStorage.setItem('stripeStatus', 'progress')
            localStorage.setItem('stripeCurrentOrgaId', String(this.authSrv.currentOrganization.id))
        } else {
            localStorage.removeItem('stripeStatus')
            localStorage.removeItem('stripeCurrentOrgaId')
        }
        return true
    }
}
