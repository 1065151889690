import { Component, Input, OnInit } from '@angular/core'
import { CalendarsService } from 'src/app/services/calendars.service'

@Component({
    selector: 'app-calendars-menu',
    templateUrl: './calendars-menu.component.html',
    styleUrls: ['./calendars-menu.component.scss']
})
export class CalendarsMenuComponent implements OnInit {
    @Input() options
    constructor(private calendarsSrv: CalendarsService) {}

    ngOnInit() {}

    onAddToCalendar(calendar: string) {
        this.calendarsSrv.addToCalendar(calendar, this.options)
    }
}
