import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HammerModule } from '@angular/platform-browser'
import { IonicModule } from '@ionic/angular'
import { ImageCropperModule } from 'ngx-image-cropper'
import { NgxMaskModule } from 'ngx-mask'
import { DirectivesModule } from 'src/app/shared/directives/directives.module'
import { CardEventHomeModule } from '../card-event-home/card-event-home.module'
import { ImageUploaderModule } from '../image-uploader/image-uploader.module'
import { EventFormComponent } from './event-form.component'

@NgModule({
    declarations: [EventFormComponent],
    imports: [
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: 'AIzaSyCvggQ2UGkRUP56wAqijAJTuOK0uUgm9u8',
                libraries: ['places']
            }
        }),
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        ImageUploaderModule,
        ImageCropperModule,
        HammerModule,
        CardEventHomeModule,
        NgxMaskModule.forRoot(),
        DirectivesModule
    ],
    exports: [EventFormComponent]
})
export class EventFormModule {}
