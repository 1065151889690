<ion-card (click)="onShowEvent()">
    <div
        style="
            height: 250px;
            max-height: 250px;
            background-image: url({{ event.img }});
            background-size: cover;
        "
    ></div>
    <ion-card-header>
        <ion-card-subtitle *ngIf="event.address.city">
            {{ event.address.city | titlecase }}
        </ion-card-subtitle>
        <ion-card-title class="truncate">{{ event.title }}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div>
            {{ getDates() }}
        </div>
        <ion-badge color="secondary" mode="ios">{{ event.category.label }}</ion-badge>
    </ion-card-content>
</ion-card>
