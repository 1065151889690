import { Component, OnInit } from '@angular/core'
import { AuthService } from './services/auth.service'
import { OrganizationService } from './services/organization.service'
import { SessionService } from './services/session.service'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    previousUrl: string = null
    currentUrl: string = null

    constructor(
        public authService: AuthService,
        private orgaSrv: OrganizationService,
        public sessionSrv: SessionService
    ) {}

    async ngOnInit() {
        if (this.authService.isAuth && localStorage.getItem('stripeStatus') == 'progress') {
            this.orgaSrv.getOrganizations().subscribe(res => {
                this.authService.currentUserOrganizations = res
                this.authService.currentOrganization =
                    this.authService.currentUserOrganizations.find(orga => {
                        return orga.id === Number(localStorage.getItem('stripeCurrentOrgaId'))
                    })
            })
        }
        // this.authService.restoreAuth()
    }
}
