import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { SessionService } from 'src/app/services/session.service'
import { UserService } from 'src/app/services/user.service'
// import { window } from 'rxjs/operators'

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() home = false

    constructor(
        private router: Router,
        public sessionSrv: SessionService,
        public userSrv: UserService
    ) {}

    // async ngOnInit() {
    //     this.deviceInfo = await Device.getInfo()
    // }

    ngOnInit() {}

    goToLegal() {
        this.router.navigate(['/legal'])
    }

    goToCgu() {
        this.router.navigate(['/cgu'])
    }

    goToMargaretValues() {
        this.router.navigate(['/valeurs'])
    }

    goToSocial(network: string) {
        switch (network) {
            case 'instagram':
                window.open('https://www.instagram.com/margaretapplication')
                break

            case 'facebook':
                window.open('https://www.facebook.com/Margaret-102072052621586')
                break

            case 'linkedin':
                window.open('https://www.linkedin.com/company/margaretapplication')
                break

            default:
                break
        }
    }

    openMailClient() {
        window.open('mailto:hello@margaret.fr')
    }
}
