import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'
import { environment } from 'src/app/shared/environments/environment'

@Component({
    selector: 'app-header-menu-popover-lg',
    templateUrl: './header-menu-popover-lg.component.html',
    styleUrls: ['./header-menu-popover-lg.component.scss']
})
export class HeaderMenuPopoverLgComponent implements OnInit {
    constructor(public authSrv: AuthService) {}

    ngOnInit() {}

    // logout() {
    //     this.authSrv.logout().subscribe(() => {})
    // }

    backToHome() {
        window.open(environment.shareRootUrl)
    }

    onAlerts() {
        window.open(`${environment.shareRootUrl}/alerts`)
    }

    onFavorites() {
        window.open(`${environment.shareRootUrl}/favorites`)
    }
}
