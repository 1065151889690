import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IonicModule } from '@ionic/angular'
import { CalendarDateModule } from '../calendar-date/calendar-date.module'
import { CardEventHomeComponent } from './card-event-home.component'

@NgModule({
    declarations: [CardEventHomeComponent],
    imports: [CommonModule, IonicModule, RouterModule, CalendarDateModule],
    exports: [CardEventHomeComponent]
})
export class CardEventHomeModule {}
