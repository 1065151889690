<div class="ion-text-center">
    <ion-icon name="ellipse" [color]="lengthOk ? 'success' : 'danger'"></ion-icon>
    8 caractères
    <ion-icon name="ellipse" [color]="oneUpperCase ? 'success' : 'danger'"></ion-icon>
    une majuscule
    <ion-icon name="ellipse" [color]="oneLowerCase ? 'success' : 'danger'"></ion-icon>
    une minuscule
    <ion-icon name="ellipse" [color]="oneDigit ? 'success' : 'danger'"></ion-icon>
    un nombre
    <ion-icon name="ellipse" [color]="oneSpecialChar ? 'success' : 'danger'"></ion-icon>
    un spécial
</div>
