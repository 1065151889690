import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IonicModule } from '@ionic/angular'
import { CalendarDateComponent } from './calendar-date.component'

@NgModule({
    declarations: [CalendarDateComponent],
    imports: [CommonModule, IonicModule],
    exports: [CalendarDateComponent]
})
export class CalendarDateModule {}
