<div
    [ngClass]="{
        'text-center align-middle text-indigo': true,
        'text-[30px]': size === 'normal',
        'text-[18px]': size === 'small',
        'text-[13px]': size === 'xs'
    }"
>
    {{ month() }}
</div>
<div
    [ngClass]="{
        'text-center align-middle text-black': true,
        'text-[40px]': size === 'normal',
        'text-[20px]': size === 'small',
        'text-[18px]': size === 'xs'
    }"
>
    {{ day() }}
</div>
