<app-header-lg></app-header-lg>

<ion-content>
    <form [formGroup]="loginForm">
        <ion-grid>
            <h1 class="ion-text-center">
                <ion-text color="primary">Nouveau mot de passe</ion-text>
            </h1>
            <h3 class="ion-text-center">
                <ion-text color="primary">
                    Veuillez saisir et confirmer votre nouveau mot de passe
                </ion-text>
            </h3>
            <ion-row>
                <ion-col sizeXs="12" sizeLg="4" offsetXs="0" offsetLg="4">
                    <div class="password">
                        <ion-label class="no-item-label">Mot de passe</ion-label>
                        <ion-input
                            [type]="passwordType"
                            required
                            placeholder="Mot de passe *"
                            formControlName="password"
                            (change)="checkPassword()"
                            (blur)="checkPassword()"
                        ></ion-input>
                        <ion-icon
                            [name]="passwordType == 'password' ? 'eye' : 'eye-off'"
                            slot="end"
                            class="password-icon"
                            (click)="onShowPassword('pass')"
                        ></ion-icon>
                    </div>
                    <app-password-check
                        [password]="loginForm.value.password"
                        (passwordOk)="isPasswordOk($event)"
                    ></app-password-check>
                    <div class="password">
                        <ion-label class="no-item-label">Confirmation</ion-label>
                        <ion-input
                            [type]="passwordConfirmType"
                            required
                            placeholder="Confirmation *"
                            formControlName="confirm"
                            (change)="checkPassword()"
                            (blur)="checkPassword()"
                        ></ion-input>
                        <ion-icon
                            [name]="passwordConfirmType == 'password' ? 'eye' : 'eye-off'"
                            slot="end"
                            class="password-icon"
                            (click)="onShowPassword('conf')"
                        ></ion-icon>
                    </div>
                    <div class="my-1 text-red-500" *ngIf="passwordError">
                        {{ passwordError }}
                    </div>
                    <div class="my-6">
                        <ion-button
                            (click)="submit()"
                            expand="block"
                            color="orange"
                            [disabled]="loginForm.invalid"
                        >
                            Valider
                        </ion-button>
                        <ion-button (click)="cancel()" expand="block" fill="clear" color="primary">
                            Annuler
                        </ion-button>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>
<app-footer></app-footer>
