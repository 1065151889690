<ion-header>
    <ion-toolbar color="primary">
        <ion-title>{{ getTitle() }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onClose()">Annuler</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="paw">
    <app-omni *ngIf="sessionSrv.authPage == 'omni'"></app-omni>
    <app-signup *ngIf="sessionSrv.authPage == 'signup'"></app-signup>
    <app-password-reset *ngIf="sessionSrv.authPage == 'password-reset'"></app-password-reset>
    <app-password-new
        *ngIf="sessionSrv.authPage == 'password-new' || authPage == 'passwordNew'"
    ></app-password-new>
</ion-content>
