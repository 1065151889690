import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, PopoverController } from '@ionic/angular'
import { compareDesc, format, getYear } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { CalendarOptions } from 'datebook'
import { EventService } from 'src/app/services/event.service'
import { MGEvent } from 'src/app/shared/models/event'
import { CalendarsMenuComponent } from '../calendars-menu/calendars-menu.component'

@Component({
    selector: 'app-card-event-home',
    templateUrl: 'card-event-home.component.html',
    styleUrls: ['card-event-home.component.scss']
})
export class CardEventHomeComponent implements OnInit {
    @Input() event: MGEvent

    constructor(
        private router: Router,
        private alertController: AlertController,
        private popoverController: PopoverController,
        private eventSrv: EventService
    ) {}

    ngOnInit() {}

    onShowEvent() {
        if (!this.eventSrv.currentEventPreview) {
            this.router.navigate(['events/', this.event.uniqueid])
        }
    }

    async presentAlert() {
        const alert = await this.alertController.create({
            header: 'Favoris',
            message: 'La gestion des favoris arrive prochainement. ',
            buttons: ['OK']
        })

        await alert.present()
    }

    async presentPopover(ev: any, event) {
        const options: CalendarOptions = {
            title: event.name,
            location: event.address.city,
            description: event.description,
            start: new Date(event.start_at),
            end: new Date(event.finish_at)
        }
        const popover = await this.popoverController.create({
            component: CalendarsMenuComponent,
            componentProps: { options },
            event: ev,
            translucent: false
        })
        await popover.present()
    }

    getDates(): string {
        const startDate = new Date(
            format(new Date(this.event.startAt), 'MM/dd/yyyy', { locale: fr })
        )
        const endDate = new Date(
            format(new Date(this.event.finishAt), 'MM/dd/yyyy', { locale: fr })
        )

        let datesStr = ''

        if (compareDesc(endDate, startDate) == 0) {
            datesStr = format(startDate, 'dd MMM yyyy', { locale: fr })
        } else if (getYear(endDate) == getYear(startDate)) {
            datesStr = `${format(startDate, 'dd MMM', { locale: fr })}-${format(
                endDate,
                'dd MMM yyyy',
                { locale: fr }
            )}`
        } else {
            datesStr = `${format(startDate, 'dd MMM yyyy', { locale: fr })}-${format(
                endDate,
                'dd MMM yyyy',
                { locale: fr }
            )}`
        }
        return datesStr
    }
}
