import { Injectable } from '@angular/core'

export declare interface PasswordPolicy {
    name: string
    regex: string
    options?: any
    text: string
    valid: boolean
}

export declare type PasswordPolicies = PasswordPolicy[]

@Injectable({
    providedIn: 'root'
})
export class PasswordValidator {
    private passwordPolicies: PasswordPolicies = [
        {
            name: 'length',
            options: {
                length: 8
            },
            regex: '^(?=.{8,}$).*$',
            text: 'Votre mot de passe doit comporter plus de 8 caractères',
            valid: false
        },
        {
            name: 'lowercase',
            regex: '^(?=.*[a-z]).*$',
            text: 'Votre mot de passe doit comporter une lettre minuscule',
            valid: false
        },
        {
            name: 'uppercase',
            regex: '^(?=.*[A-Z]).*$',
            text: 'Votre mot de passe doit comporter au moins une lettre majuscule',
            valid: false
        },
        {
            name: 'digit',
            regex: '^(?=.*[0-9]).*$',
            text: 'Votre mot de passe doit comporter au moins un chiffre',
            valid: false
        },
        {
            name: 'special_char',
            // regex: '^(?=.*\\W).*$',
            regex: '^(?=.*[!#$%&()*+,-./:;=?@_]).*$',
            text: 'Votre mot de passe doit comporter au moins un caractère spécial',
            valid: false
        }
    ]

    constructor() {}

    getPasswordPolicy(): PasswordPolicies {
        return this.passwordPolicies
    }

    checkPasswordPolicy(password): {
        policies: PasswordPolicies
        valid: boolean
    } {
        const policies = [...this.passwordPolicies]

        const valids = policies.map(policy => {
            const ruleRegex = new RegExp(policy.regex)
            policy.valid = ruleRegex.test(password)
            return policy.valid
        })

        return {
            policies,
            valid: valids.every(v => v)
        }
    }
}
